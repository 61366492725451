body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @font-face {
  font-family: 'Barcode';
  src: url('/absolute/path/to/Barcode.ttf') format('truetype');
} */

.barcode {
  font-family: "Barcode font";
}

.title {
  font-family: "Press Start 2P";
}

.text-gradient {
  background: linear-gradient(to right, #a6a6a6, #404040);
  -webkit-background-clip: text;
  color: transparent;
}

.text-stroke {
  -webkit-text-stroke: 1px #000;
  text-stroke: 1px #000;
}

@tailwind base;
@tailwind components;
@tailwind utilities;