h1 {
  display: flex;
  flex-direction: row;
  font-family: "Intro", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  /* Warning: no fallback */

  background: -webkit-linear-gradient(-86deg, #a6a6a6, #404040);
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-stroke-width: 4px;
  color: white;
}
h1:before,
h1:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
h1:before {
  margin-right: 10px;
}
h1:after {
  margin-left: 10px;
}

p {
  line-height: 100px;
}
