h1 {
  display: flex;
  flex-direction: row;
}
h1:before,
h1:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
h1:before {
  margin-right: 10px;
}
h1:after {
  margin-left: 10px;
}

.flex-row {
  flex-direction: row;
}
.slide-container {
  @media (max-width: 768px) {
    .flex-row {
      flex-direction: column;
    }
  }
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px; /* Add some spacing between images */
}

.color {
  color: white;
}
